<template>
  <section>
    <div class="content-container">
      <div class="block-background background" ref="background"></div>
      <div class="body-text">
        <div class="text-container" ref="body">
          <h3 v-if="paragraphTitle !== null">{{ paragraphTitle }}</h3>
          <p v-html="sanitise(data.body[0].value)"></p>
        </div>
      </div>
      <div>
        <div class="image-container">
          <ImageZooming
            class="image-sizer"
            :src="data.field_bild[0].url"
            :alt="data.field_bild[0].alt"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { visualMixin } from "@/mixins/visualMixin.js";
import { httpMixin } from "@/mixins/httpMixin.js";
import ImageZooming from "@/components/ImageZooming.vue";
import ScrollMagic from "scrollmagic";

export default {
  props: {
    data: Object
  },

  mixins: [visualMixin, httpMixin],

  components: {
    ImageZooming
  },

  computed: {
    paragraphTitle: function() {
      if (
        this.data.field_show_title.length &&
        this.data.field_show_title[0].value === true
      ) {
        return this.data.title[0].value;
      }
      return null;
    }
  },

  mounted: function() {
    new ScrollMagic.Scene({
      triggerElement: this.$refs.background,
      offset: 0,
      triggerHook: 0.8,
      reverse: false
    })
      .setClassToggle(this.$refs.background, "block-background--visible")
      .addTo(this.animationController);

    new ScrollMagic.Scene({
      triggerElement: this.$refs.background,
      offset: 50,
      triggerHook: 0.75,
      reverse: false
    })
      .setClassToggle(this.$refs.body, "text-container--visible")
      .addTo(this.animationController);
  }
};
</script>

<style scoped>
section {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  margin: 0 -16px;
}

.content-container {
  display: grid;
  gap: 0 24px;
  grid-template-columns: 1fr;
  width: 100%;
  position: relative;
}

.block-background {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 0;
  left: 0%;
  transform: translateX(100vw);
  transition: transform 0.8s ease-in-out;
}

.block-background--visible {
  transform: none;
}

.body-text {
  padding: 36px 16px 0;
  max-width: 100vw;
  box-sizing: border-box;
}

.image-container {
  position: relative;
  overflow: hidden;
  transform: translateY(72px);
}

.image-sizer {
  width: 100%;
  max-height: 66vh;
}

.image-sizer img {
  width: 100%;
  height: 100%;
}

.text-container {
  text-align: left;
  position: relative;
  transition: clip-path 0.8s ease-in-out;
  clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
  padding-right: 60px;
}

.text-container--visible {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

@media screen and (min-width: 768px) and (orientation: landscape) {
  section {
    padding-right: 56px;
    margin: 0;
  }

  .content-container {
    gap: 0 24px;
    grid-template-columns: 50% 50%;
  }

  .body-text {
    padding: 72px 48px;
    max-width: unset;
    box-sizing: content-box;
  }
}
</style>
